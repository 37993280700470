"use client";

import bg from "@/app/(auth)/_components/geometricBG.png";
import cartrackedLogo from "@/app/_components/logos/cartracked-logo-black-bg.png";

import { SignIn } from "@clerk/nextjs";
import { usePathname } from "next/navigation";

const SignInPage = () => {
  return (
    <>
      <div className="flex w-screen h-screen">
        <div
          className="flex flex-col object-contain bg-cover w-full h-full"
          style={{
            backgroundImage: `url(${bg.src})`,
            objectFit: "contain",
          }}
        >
          <div className="flex pl-4">
            <img
              src={cartrackedLogo.src}
              alt="CarTracked Logo"
              className="w-48 h-48"
            />
          </div>
          <div className="flex justify-center w-full h-full">
            <SignIn afterSignInUrl={"/v2/dashboard"} path="/v2/sign-in" />
          </div>
        </div>
      </div>
    </>
  );
};

export default SignInPage;
